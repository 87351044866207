<template>
  <SelectBlockSex
    :model-value="modelValue"
    @update:model-value="
      $emit('update:modelValue', $event);
      sex = $event;
    "
    @focus="isErrorSex = false"
    @blur="validateSex()"
    :error="isErrorSex"
  />
</template>

<script>
import { ref, computed } from "@vue/reactivity";
import SelectBlockSex from "@/components/UI/SelectBlockSex.vue";
import { watch } from "vue";

export default {
  components: { SelectBlockSex },

  props: {
    modelValue: String,
  },

  emits: ["update:modelValue", "update:isValidSex"],

  setup(props, { emit }) {
    const sex = ref(props.modelValue);

    // Validation on emptyness
    const isErrorSex = ref(false);
    const validateSex = () => {
      isErrorSex.value = !sex.value;
    };
    const isValidSex = computed(() => {
      return !!sex.value;
    });

    watch(
      isValidSex,
      (newIsValid) => {
        emit("update:isValidSex", newIsValid);
      },
      { immediate: true }
    );

    return {
      sex,
      isErrorSex,
      validateSex,
      isValidSex,
    };
  },
};
</script>

<style scoped></style>
