<template>
  <SelectBlock
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    :options="sexOptions"
    :name="'sex'"
    :label="'Plec'"
    :small="true"
    :disabled="disabled"
  />
</template>

<script>
import SelectBlock from "@/components/UI/Base/SelectBlock.vue";

export default {
  components: { SelectBlock },

  props: {
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  setup() {
    const sexOptions = [
      {
        value: "female",
        label: "Kobieta",
      },
      {
        value: "male",
        label: "Mieszczyzna",
      },
    ];

    return {
      sexOptions,
    };
  },
};
</script>
