<template>
  <InputText
    :type="'text'"
    :name="'last-name'"
    :autocomplete="'off'"
    :spellcheck="false"
    :label="'Nazwisko'"
    :placeholder="'Wierzchoślawowna'"
    :disabled="disabled"
    @update:model-value="$emit('update:modelValue', $event)"
    :model-value="modelValue"
  />
</template>

<script>
import InputText from "@/components/UI/Base/InputText.vue";

export default {
  components: { InputText },

  props: {
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],
};
</script>
