<template>
  <InputTextDOB
    v-model:dob-date="dob.date"
    @focus="dob.isError = false"
    @blur="checkForError()"
    :error="dob.isError"
  />
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "vue";
import { dateToString } from "@/helpers";

import InputTextDOB from "@/components/UI/InputTextDOB.vue";

export default {
  components: { InputTextDOB },

  props: {
    dobDate: [Date, null],
  },

  emits: ["update:isValidDob", "update:dobDate"],

  setup(props, { emit }) {
    const dob = reactive({
      date: props.dobDate,
      isError: false,
    });

    watch(
      () => dob.date,
      (newDobDate) => {
        emit("update:dobDate", newDobDate);
      }
    );

    watch(
      () => props.dobDate,
      (newDobDate) => {
        dob.date = newDobDate;
      }
    );

    const isValidDob = computed(() => {
      const dobDate = dob.date;
      if (dobDate === null) return false;

      const date16YearsAgo = new Date();
      date16YearsAgo.setFullYear(new Date().getFullYear() - 16);
      const isOlder16 = dobDate.getTime() < date16YearsAgo.getTime();
      if (!isOlder16) {
        return false;
      }

      const dobString = dateToString(dobDate);
      return /d|m|y|_/.test(dobString) === false;
    });

    watch(
      isValidDob,
      (newIsValid) => {
        emit("update:isValidDob", newIsValid);
      },
      { immediate: true }
    );

    const checkForError = () => {
      dob.isError = !isValidDob.value;
    };

    return {
      dob,
      checkForError,
      isValidDob,
    };
  },
};
</script>
