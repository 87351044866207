<template>
  <InputTextNameLast
    v-model="nameLast"
    @focus="isErrorNameLast = false"
    @blur="validateNameLast()"
    :error="isErrorNameLast"
  />
</template>

<script>
import InputTextNameLast from "@/components/UI/InputTextNameLast.vue";
import { ref, computed } from "@vue/reactivity";
import { watch } from "vue";

export default {
  components: { InputTextNameLast },

  props: {
    modelValue: String,
  },

  emits: ["update:modelValue", "update:isValidNameLast"],

  setup(props, { emit }) {
    const nameLast = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newName) => {
        nameLast.value = newName;
      }
    );

    watch(nameLast, (newName) => {
      emit("update:modelValue", newName);
    });

    const isErrorNameLast = ref(false);

    const validateNameLast = () => {
      isErrorNameLast.value = !isValidNameLast.value;
    };
    const isValidNameLast = computed(() => {
      if (nameLast.value === null) return false;
      return nameLast.value.length > 1;
    });

    watch(
      isValidNameLast,
      (newIsValid) => {
        emit("update:isValidNameLast", newIsValid);
      },
      { immediate: true }
    );

    return {
      nameLast,
      isErrorNameLast,
      validateNameLast,
      isValidNameLast,
    };
  },
};
</script>

<style>
/* For styles loook into InputText < style > block */
</style>
